import React, { PureComponent } from "react";
import axios, { AxiosResponse } from 'axios';
import Icon from "../components/icon/icon";
import { SearchHeaderState } from "../types/SearchInterfaces";
import Pagination from '../components/pagination/pagination';
import { insertUrlParam, removeUrlParameter } from "../../utils/URLparamsHelper";

class SearchContainer extends PureComponent<{},SearchHeaderState> {
  constructor(props) {
    super(props);
    this.state = {
        searchTerm: "",
        searchResultsList: [],
        flitreredResultsList : [],
        currentPage: 1,
        PageSize: 10,
        allList: [],
        peopleList: [],
        expertiseList: [],
        insightsList: [],
        allResultActive: true,
        peopleActive: false,
        expertiseActive: false,
        insightsActive: false,
        searchResult: false,
        resultsLength: 0
    }
    this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
    this.handleSearchFetch = this.handleSearchFetch.bind(this);
  }

  componentDidMount(): void {
    const endpointsData = JSON.parse(document.querySelector(`[data-config-id="endpoints"]`).innerHTML);
    const urlSearchParams: any = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const searchterm = params.searchterm ? params.searchterm : "";
    this.setState({searchTerm: searchterm }, () => { this.handleSearchBtnClick()}) 
  }

  handleSearchFetch(searchTerm): void {
    const { searchResultsList} = this.state
    this.setState({searchResult: false});
    axios.get(`/api/search/get?searchterm=${searchTerm}`).then((response: AxiosResponse) => {

        this.setState({searchResultsList : response.data, flitreredResultsList : response.data, resultsLength: response.data.length }, () => {
          this.handlePagination(1)
        })
        let peopleList = [];
        let expertiseList = [];
        let insightsList = [];
        this.state.searchResultsList.forEach(data=>{
        if(data.Type==='People'){
        peopleList.push(data)
      }
        else if(data.Type==='Expertise' || data.Type==='Service' || data.Type==='Sector'){
        expertiseList.push(data)
      }
        else if(data.Type==='Insight'){
        insightsList.push(data)
      }
    })
      this.setState({peopleList,expertiseList,insightsList,allList: response.data,searchResult: true})
      if(this.state.allResultActive){
        this.setState({flitreredResultsList:response.data, peopleList, expertiseList, insightsList, allList: response.data, searchResult: true })
      }
      else if(this.state.peopleActive){
        this.setState({flitreredResultsList:peopleList, peopleList, expertiseList, insightsList, allList: response.data, searchResult: true })
      }
      else if(this.state.expertiseActive){
        this.setState({flitreredResultsList:expertiseList, peopleList, expertiseList, insightsList, allList: response.data, searchResult: true })
      }
      else if(this.state.insightsActive){
        this.setState({flitreredResultsList:insightsList, peopleList, expertiseList, insightsList, allList: response.data, searchResult: true })
      }
      })
    .catch(function (error: AxiosResponse) {});
  }

  handleSearchBtnClick(): void {
    const { searchTerm } = this.state;
    if(searchTerm !== "") {
      insertUrlParam("searchterm", searchTerm)
    } else {
      removeUrlParameter("searchterm")
    }
    this.handleSearchFetch(searchTerm)
  }

  handleSearchEnterPress(e){
    if(e.key === 'Enter') {
      this.handleSearchBtnClick()
    }
  }

  handleSearchTextChange(event) {
    this.setState({searchTerm : event.target.value});
  }

  handlePagination(page) {
    return this.setState({currentPage : page }, () => {
        const { allResultActive, peopleActive, peopleList,expertiseList,insightsList,insightsActive,expertiseActive,currentPage,PageSize,searchResultsList,flitreredResultsList } = this.state
             const firstPageIndex = (currentPage - 1) * PageSize ;
            const lastPageIndex = firstPageIndex + PageSize;
            if(allResultActive)
            {
              this.setState({ flitreredResultsList : searchResultsList.slice(firstPageIndex, lastPageIndex)})
            }
            else if(peopleActive) 
            {
              this.setState({ flitreredResultsList : peopleList.slice(firstPageIndex, lastPageIndex)})
            }
            else if(expertiseActive) 
            {
              this.setState({ flitreredResultsList : expertiseList.slice(firstPageIndex, lastPageIndex)})
            }
            else if(insightsActive) 
            {
              this.setState({ flitreredResultsList : insightsList.slice(firstPageIndex, lastPageIndex)})
            }
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            })
      })
  }

  handleFilter(type: string) {
    const {
      allList,
      peopleList,
      expertiseList,
      insightsList,
      searchResultsList
    } = this.state;
    
    switch (type) {
      case 'people':
        this.setState({
          flitreredResultsList: peopleList,
          allResultActive: false,
          peopleActive: true,
          expertiseActive: false,
          insightsActive: false,
          resultsLength: peopleList.length,
          currentPage:1
        }, () => {
          this.handlePagination(1)
        });
        break;
      case 'expertise':
        this.setState({
          flitreredResultsList: expertiseList,
          allResultActive: false,
          peopleActive: false,
          expertiseActive: true,
          insightsActive: false,
          resultsLength: expertiseList.length
        }, () => {
          this.handlePagination(1)
        });
        break;
      case 'insights':
        this.setState({
          flitreredResultsList: insightsList,
          allResultActive: false,
          peopleActive: false,
          expertiseActive: false,
          insightsActive: true,
          resultsLength: insightsList.length
        }, () => {
          this.handlePagination(1)
        });
        break;
      default:
        this.setState({
          flitreredResultsList: allList,
          allResultActive: true,
          peopleActive: false,
          expertiseActive: false,
          insightsActive: false,
          resultsLength: searchResultsList.length,
          currentPage:1
        }, () => {
          this.handlePagination(1)
        });
    }
  }

  render(): React.ReactNode {
    const {
      searchTerm,
      searchResultsList,
      currentPage,
      flitreredResultsList,
      allList,
      peopleList,
      expertiseList,
      insightsList,
      allResultActive,
      peopleActive,expertiseActive,insightsActive,resultsLength,searchResult

    } = this.state;       
    return (
     <>
      <div className="merw-container">
        <div className="search-result">
            <div className="search-result__container">
                <div className="search-result__input">
                    <Icon className="search-result__search__icon__desktop" name="search"/>
                    <input placeholder="Search the site" className="search-result__input-field" type="text" value={searchTerm} onChange={(e) => this.handleSearchTextChange(e)} onKeyPress={(e) => this.handleSearchEnterPress(e)}/>
                    <button className="search-result__input--btn" onClick={() => this.handleSearchBtnClick()}><span className="mobile-icon"><Icon className="search-result__search__icon__btn" name="search"/></span><span className="desktop-text">Search</span></button>
                </div>
            </div>
        </div>
        <div className="search-result__tabs">
            <div className="search-result__tabs__wrapper">
              <div className={`${
                    allResultActive ? 'btn-active' : 'search-result__tabs__container'
                  }`}>
                <button
                 className='search-result__tabs__btn'
                  onClick={() => this.handleFilter('allResult')}
                >
                  All results ({allList?.length})
                </button>
              </div>
              <div  className={`${peopleActive ? 'btn-active' : 'search-result__tabs__container'}`}>
                <button
                 className='search-result__tabs__btn'
                  onClick={() => this.handleFilter('people')}
                >
                  People ({peopleList?.length})
                </button>
              </div>
              <div className={`${
                    expertiseActive ? 'btn-active' : 'search-result__tabs__container'
                  }`}>
                <button
                 className='search-result__tabs__btn'
                  onClick={() => this.handleFilter('expertise')}
                >
                  Expertise ({expertiseList?.length})
                </button>
              </div>
              <div className={`${insightsActive ? 'btn-active' : 'search-result__tabs__container'}`}>
                <button
                className='search-result__tabs__btn'
                  onClick={() => this.handleFilter('insights')}
                >
                  Insights ({insightsList?.length})
                </button>
              </div>
            </div>
          </div>
          {!searchResult && <div className="loader">
              <svg
                  className="loading-icon"
                  viewBox="0 0 15 15"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  focusable="false"
                >
                <use xlinkHref="/public/assets/icons/spritemap.svg#loader"></use>
                </svg>
                </div>}
                { searchResult &&
        <div className="search-result__container">
        { flitreredResultsList?.length > 0 ? flitreredResultsList.map((el, index) => (
        <div className="search-result__wrapper">
            <div className="search-result__left-content">
                <a href={el.PageRelativeURL} className="search-result__left-content__title">{el.Title}</a>
                {el.PublishedOn && <p className="search-result__left-content__date m-0">{el.PublishedOn}</p>}
                { el.Description && <p className="search-result__left-content__description m-0">
                  {el.Description}
                </p> }
                { el.JobTitle && <p className="search-result__left-content__description m-0">
                  <span>{el.JobTitle}, </span> {el.PrimaryPracticeArea}
                </p>}
               { el.Type === "People" && <div className="search-result__left-content__icons">
                    <a href={el.BusinessPhone} className="search-result__left-content__icons__phone">
                        <Icon name="phone"/>
                    </a>
                    <a href={el.Email} className="search-result__left-content__icons__email">
                        <Icon name="email"/>
                    </a>
                    <a href={el.LinkedIn} className="search-result__left-content__icons__linkedin">
                        <Icon name="linkedIn"/>
                    </a>
                </div> }
            </div>
           { el.Image && <a href={el.PageRelativeURL} style={{backgroundImage: `url(${el.Image})`}} className="search-result__right-content"></a> }
        </div>
         )) : <div className="search-result--no-result">
            <h5>No Result Found</h5>
           </div> }
           </div>
        }
          <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={resultsLength}
        pageSize={10}
        onPageChange={page => {this.handlePagination(page)}}
        />  
      </div>
     </>
    )
  }
}

export default SearchContainer;
